@import '../../../../variables.scss';

.QuoteCloneModal__Frame {
    width: 600px;
}

.QuoteCloneModal__MessagePanel {
    margin-top: -12px;
    margin-bottom: 24px;
}
