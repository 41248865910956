@import '../../../../variables.scss';

.PurchasesTable__DataTable {
    .DataTable__DataCell--sidemark {
        font-weight: $font-weight-semi-bold;
    }

    .DataTable__DataCell--status {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.PurchasesTable__FilterBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
}

.PurchasesTable__FilterBar__Actions {
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.PurchasesTable__StatusCell {
    display: flex;
    gap: 6px;
}

.PurchasesTable__SupplierStatusCell {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.PurchasesTable__TotalCell {
    white-space: nowrap;

    .count {
        small {
            font-size: 12px;
            color: $color-secondary-base;
        }
    }

    .price {
        font-size: 13px;
        color: $color-secondary-base;
    }
}
