@import '../../../../variables.scss';

.OrderUpdatesMessagePanel__Actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.OrderUpdatesMessagePanel__DetailsModal__Frame {
    width: 600px;
    font-size: 16px;
    line-height: 150%;
}

.OrderUpdatesMessagePanel__DetailsModal__Header {
    .PageHeader__TitleContainer__Title {
        font-size: 20px;
    }
}

.OrderUpdatesMessagePanel__DetailsModal__List {
    > li {
        font-weight: $font-weight-semi-bold;
        padding-bottom: 6px;
    }
}

.OrderUpdatesMessagePanel__DetailsModal__ProductRow {
    .updates li {
        font-weight: $font-weight-normal;
        padding-top: 4px;
        font-size: 14px;
    }
}

.OrderUpdatesMessagePanel__DetailsModal__Footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 0 0 12px 12px;

    @media #{$small-down} {
        position: fixed;
        width: 100%;
        bottom: 0;
        padding: 24px 16px;
        margin: 0 -16px;
    }
    @media #{$medium-up} {
        position: sticky;
        bottom: 0;
        margin: 0 -32px;
        padding: 24px 32px 0;
    }
}

.OrderUpdatesMessagePanel__DetailsModal__Footer__ApplyButton {
    @media #{$very-small-only} {
        width: 100%;
    }
    @media #{$small-up} {
        min-width: 180px;
    }
}
