.MySwitchInput {
    position: relative;
}

.MySwitchInput__OutlinedInput {
    height: 40px;
    box-sizing: border-box;
    position: relative;

    // when input has a label we also draw the outline
    .MySwitchInput--with-label & {
        width: 80px;
        min-width: 80px;
    }

    // when input has no label we hide the outline
    .MySwitchInput--no-label & {
        width: 50px;
        min-width: 50px;

        .MuiOutlinedInput-notchedOutline {
            border: 0;
        }
    }
}

.MySwitchInput__SwitchWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
