@import '../../../../variables.scss';

.QuoteStatusBadge {
    &.DRAFT {
        background-color: $color-grey-light;
        border-color: $color-grey-light;
        color: white;
    }
    &.ACCEPTED {
        background-color: $color-checked-green;
        border-color: $color-checked-green;
        color: white;
    }
}
