@import '../../../../variables.scss';

.WorkflowStatusSelect {
    &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 0px;
    }

    &.MuiInputBase-root {
        height: auto;
        min-height: 24px;

        // layout for nice focus state
        .WorkflowStatusSelect__WithIcon {
            display: inline-flex;
            border-radius: 20px;
            padding-left: 4px;
            padding-right: 8px;
            margin-left: -4px;
        }
        &.Mui-focused {
            .WorkflowStatusSelect__WithIcon {
                outline: 2px solid $color-primary-base;
            }
        }
    }

    .MuiOutlinedInput-input {
        padding: 2px !important;
        overflow: visible;
        margin-bottom: -6px;
    }

    .MuiOutlinedInput-notchedOutline {
        border-width: 0px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 0px;
    }

    .MuiSvgIcon-root {
        display: none;
    }
}

.WorkflowStatusSelect__Menu {
    .MuiListSubheader-root {
        line-height: normal;
        margin-bottom: 8px;

        &:not(:first-child) {
            margin-top: 12px;
        }
    }
}

.WorkflowStatusSelect__WithIcon {
    display: flex;
    gap: 6px;
    align-items: center;
}
