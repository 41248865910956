@import '../../../../variables.scss';

.DealFlagSelect {
    .MuiInputBase-root {
        height: auto;
        min-height: 24px;

        // layout for nice focus state
        .DealFlagPill {
            border: 1px solid white;
        }
        &.Mui-focused {
            .DealFlagPill {
                box-shadow: 0 0 0 2px $color-primary-base;
            }
        }
    }

    .MuiOutlinedInput-input {
        padding: 0px !important;
        overflow: visible;
    }

    .MuiOutlinedInput-notchedOutline {
        border-width: 0px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 0px;
    }

    .MuiSvgIcon-root {
        display: none;
    }
}

.DealFlagSelect__Menu {
    .MuiMenuItem-root {
        min-height: auto;
    }
}
