@import '../../../../variables.scss';

.PurchaseOrderDetailModal__Frame {
    width: 1100px;

    &:not(.MyModal__Frame--Loading) {
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
    }
}

.PurchaseOrderDetailModal__Header {
    margin-bottom: 18px;
    padding-bottom: 0;
    border-bottom: 0;
}

.PurchaseOrderDetailModal__PageHeader__TitleContext {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-wrap: wrap;
}

.PurchaseOrderDetailModal__PageHeader__Tuid {
    white-space: nowrap;
}

.PurchaseOrderDetailModal__OrderUpdatesMessagePanel {
    margin-top: 12px;
}

.PurchaseOrderDetailModal__Body {
    display: flex;
    flex-direction: column;
}

.PurchaseOrderDetailModal__Details {
    position: relative;

    // make space for the edit button when it is visible
    &.editable {
        padding-right: 112px;
    }
}

.PurchaseOrderDetailModal__Details__EditButton {
    position: absolute;
    top: 0;

    @media #{$small-down} {
        right: 16px;
    }
    @media #{$medium-up} {
        right: 0;
    }
}
