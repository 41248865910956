@import '../../variables.scss';

.PropertyEditSwitch__DisplayCheck {
    font-size: 24px;
}

.PropertyEditSwitch__InputWrapper {
    .MySwitchInput__OutlinedInput {
        // override the default background color set on all inputs within PropertyEdit__InputWrapper
        background-color: transparent;
    }
}
