@import '../../../../variables.scss';

.WorkflowPhaseIcon {
    font-size: 125%;
    align-items: center;
    display: flex;

    &.NEW {
        color: $color-grey-light;
    }

    &.QUOTING {
        color: $color-primary-base;
    }

    &.IN_PROGRESS {
        color: $color-primary-base;
    }

    &.CLOSED {
        font-size: 140%;
        color: $color-accent-base;
    }

    &.CANCELLED {
        color: $color-danger-base;
    }
}
