@import '../../../../variables.scss';

.DealRelatedDeals__DataTable {
    margin-bottom: 12px;

    @media #{$small-down} {
        // fill frame by negating modal padding
        margin-inline: -$modalFramePaddingSmall;
    }
}

.DealRelatedDeals__Id {
    display: flex;
    gap: 6px;
}

.DealRelatedDeals__Description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.DealRelatedDeals__ArchiveColumn {
    position: sticky;
    right: 0;
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.MuiButton-root.DealRelatedDeals__ArchiveButton {
    padding: 0;
    min-width: 28px;

    .MyButton__Inner__IconLeft {
        font-size: 16px;
    }
}

.DealRelatedDeals__AddDealPopover {
    max-height: 230px;
    display: flex;
    flex-direction: column;

    @media #{$medium-up} {
        min-width: 540px;
    }

    @media #{$small-down} {
        min-width: 300px;
    }
}

.DealRelatedDeals__AddDealPopover__Header {
    display: block;
    position: relative;
    padding: 12px;
}

.MuiButton-root.DealRelatedDeals__AddDealPopover__CloseButton {
    position: absolute;
    right: 12px;
    min-width: 0;
    padding: 0;
    width: 40px;
}

.DealRelatedDeals__AddDealPopover__Filters {
    width: 100%;
}

.DealRelatedDeals__AddDealPopover__SearchFilter {
    // leave space for the close button
    width: calc(100% - 48px);

    .MyTextInput {
        width: 100%;
    }
}

.DealRelatedDeals__AddDealPopover__Table {
    flex: 1;
    overflow-y: auto;

    .DataTable__DataRow--clickable:hover {
        background-color: $color-secondary-lighter;
    }

    .DataTable__DataCell {
        &:first-child {
            padding-left: 16px;
        }
        &:last-child {
            padding-right: 16px;
        }
    }
}

.DealRelatedDeals__AddedRow {
    color: $color-grey-base;
    background-color: $color-secondary-lightest;
}

.DealRelatedDeals__AddedRow__CheckBox {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;

    .checkIcon {
        color: $color-checked-green;
        font-size: 16px;
    }
    .plusIcon {
        color: $color-primary-base;
    }
}
