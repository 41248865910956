@import '../../variables.scss';

.MySelectInput__Menu {
    .MuiMenuItem-root {
        &.Mui-selected {
            background-color: $color-primary-base;
            color: white;
        }
    }
    .MuiMenu-paper {
        transition-duration: 0.1s !important;
    }
}

.MySelectInput__MenuItem--Placeholder {
    color: $color-grey-light;
}
