.DashboardSection {
    & + .DashboardSection {
        margin-top: 32px;
    }

    .SectionHeader {
        margin-bottom: 8px;
        border-bottom: 0;

        .SectionHeader__Title,
        .SectionHeader__TitleContext {
            font-size: 18px;
        }
    }
}

.DashboardSection__Content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
}
