@import '../../../../variables.scss';

.PurchaseOrderCloneModal__Frame {
    width: 600px;
}

.PurchaseOrderCloneModal__MessagePanel {
    margin-top: -12px;
    margin-bottom: 24px;
}
