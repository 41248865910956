@import '../../../../variables.scss';

.ProductDimensionDisplay {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.ProductDimensionDisplay__Badge {
    font-size: 12px;
    padding: 0 12px;
    background-color: $color-secondary-light;
    color: $color-secondary-dark;
    border-radius: 24px;
}
