@import '../../variables.scss';

.Kanban {
    display: flex;
    height: 100%;

    &--refreshing {
        opacity: 0.5;
        pointer-events: none;
    }
}

.Kanban__Column {
    display: flex;
    flex-direction: column;
    width: 280px;
    gap: 4px;

    // account for padding in Kanban__Column__DragArea
    // make sure edge aligns with page margin
    &:first-child {
        margin-left: -4px;
    }
}

.Kanban__Column__DragArea {
    height: 100%;
    overflow-y: auto;
    flex: 1;
    border-radius: 5px;
    border: 1px solid transparent;
    padding: 4px;
    padding-bottom: 24px;
    scrollbar-width: thin;

    .smooth-dnd-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        min-height: 100%;
    }

    // disable touch actions
    .smooth-dnd-draggable-wrapper {
        touch-action: pan-x pan-y;
        -ms-touch-action: pan-x pan-y;
    }
}

.Kanban__Column__DragArea--Droppable {
    border: 1px dashed $color-primary-base;
    background-color: transparentize($color-primary-light, 0.9);
}

.Kanban__Column__Header {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0 8px;
}

.Kanban__Column__Header__Title {
    font-size: 14px;
    margin: 0;
}

.Kanban__Column__Header__Count {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    min-width: 20px;
    height: 20px;
}

.Kanban__Column__Empty {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    background-color: transparentize($color-secondary-base, 0.7);
    background-color: $color-secondary-light;
    height: 5px;
}

.Kanban__Card {
    display: block;
    background-color: white;
    border-radius: 5px;
    border: 1px solid $color-grey-lighter;

    &.DRAGGING {
        box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.4);
    }
}
