@import '../../../../variables.scss';

.DealFlagPill {
    border-radius: 24px;

    white-space: nowrap;
    display: inline-block;

    &.small {
        font-size: 12px;
        padding: 1px 8px;
    }
    &.large {
        font-size: 14px;
        padding: 1px 12px;
    }

    // default colours
    background-color: $color-secondary-light;
    color: $color-secondary-dark;

    &.WARNING {
        background-color: $color-warning-base;
        color: $color-warning-darker;
    }
    &.ACTIVE {
        background-color: $color-primary-base;
        color: white;
    }
    &.SUCCESS {
        background-color: $color-checked-green;
        color: white;
    }
    &.DANGER {
        background-color: $color-danger-base;
        color: white;
    }
}
