// Colors - Greys
$color-grey-darker: #2f2f2f;
$color-grey-dark: #454545;
$color-grey-base: #7e8486;
$color-grey-light: #b8b8b8;
$color-grey-lighter: #ece9e9;
$color-grey-lightest: #f5f5f5;

// Colors - Primary
$color-primary-darker: #0c355b;
$color-primary-dark: #1c66aa;
$color-primary-base: #009eda;
$color-primary-light: #7ecfee;
$color-primary-lighter: #c0edff;
$color-primary-lightest: #f0faff;

// Colors - Secondary
$color-secondary-darker: #141b26;
$color-secondary-dark: #2b323f;
$color-secondary-base: #6a758b;
$color-secondary-light: #dfe6f0;
$color-secondary-lighter: #f0f4fb;
$color-secondary-lightest: #f8faff;

// Colors - Accent
$color-accent-darker: #404a00;
$color-accent-dark: #7d8f0a;
$color-accent-base: #bfd730;
$color-accent-light: #e2f479;
$color-accent-lighter: #eef6bc;
$color-accent-lightest: #fcffe9;

// Colors - Danger (Reds)
$color-danger-darker: #650c0b;
$color-danger-dark: #811621;
$color-danger-base: #d83747;
$color-danger-light: #ff6272;
$color-danger-lighter: #ffeded;

// Colors - Danger (Reds)
$color-warning-darker: #5f4400;
$color-warning-dark: #c2951d;
$color-warning-base: #f9d577;
$color-warning-light: #ffeab5;
$color-warning-lighter: #fffdf6;

// Colors - Misc
$color-link-base: $color-primary-base;
$color-link-dark: #005d82;
$color-warning: #fcc351;
$color-error: #d83747;
$color-checked-green: #00c797;

:export {
    greyDarker: $color-grey-darker;
    greyDark: $color-grey-dark;
    greyBase: $color-grey-base;
    greyLight: $color-grey-light;
    greyLighter: $color-grey-lighter;
    greyLightest: $color-grey-lightest;
    primaryDarker: $color-primary-darker;
    primaryDark: $color-primary-dark;
    primaryBase: $color-primary-base;
    primaryLight: $color-primary-light;
    primaryLighter: $color-primary-lighter;
    primaryLightest: $color-primary-lightest;
    secondaryDarker: $color-secondary-darker;
    secondaryDark: $color-secondary-dark;
    secondaryBase: $color-secondary-base;
    secondaryLight: $color-secondary-light;
    secondaryLighter: $color-secondary-lighter;
    secondaryLightest: $color-secondary-lightest;
    accentDarker: $color-accent-darker;
    accentDark: $color-accent-dark;
    accentBase: $color-accent-base;
    accentLight: $color-accent-light;
    accentLighter: $color-accent-lighter;
    accentLightest: $color-accent-lightest;
    dangerDarker: $color-danger-darker;
    dangerDark: $color-danger-dark;
    dangerBase: $color-danger-base;
    dangerLight: $color-danger-light;
    dangerLighter: $color-danger-lighter;
    dangerLighter: $color-danger-lighter;
    warningDarker: $color-warning-darker;
    warningDark: $color-warning-dark;
    warningBase: $color-warning-base;
    warningLight: $color-warning-light;
    warningLighter: $color-warning-lighter;
    warningLighter: $color-warning-lighter;
    linkBase: $color-link-base;
    linkDark: $color-link-dark;
    warning: $color-warning;
    error: $color-error;
    checkedGreen: $color-checked-green;
}
