@import 'variables.scss';

.CustomerQuotePage {
    max-width: 1000px;
    margin: auto;

    @media #{$small-down} {
        padding: 12px;
    }
    @media #{$medium-up} {
        padding: 12px 24px;
    }
}

.CustomerQuotePage__TopBar {
    display: flex;
    margin-bottom: 24px;
}

.CustomerQuotePage__TopBar__Logo--DefaultImage {
    height: 24px;
    margin-top: 12px;
}

.CustomerQuotePage__TopBar__Logo--HostedImage {
    height: 48px;
    object-fit: contain;
}

.CustomerQuotePage__Loading {
    min-height: 100dvh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CustomerQuotePage__Error {
    min-height: 100dvh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 24px;

    p {
        margin-block-start: 0;
    }
}
