@import '../../../../variables.scss';

.QuotePdfModal__Frame {
    display: flex;
    flex-direction: column;
    width: 1200px;
    padding-bottom: 0;
    transition: width 0.3s ease-in-out;
}

.QuotePdfModal__Header__Inner {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.QuotePdfModal__Header__PageHeader {
    margin-bottom: 12px !important;
}

.QuotePdfModal__Header__TitleContext {
    white-space: nowrap;
}
.QuotePdfModal__Header__DownloadPDFButton {
    max-width: 160px;
}

.QuotePdfModal__Body {
    display: flex;
    flex-direction: column;

    @media #{$small-down} {
        margin: -12px -16px 0;
    }
    @media #{$medium-up} {
        margin: -24px -32px 0;
    }
}

.QuotePdfModal__RenderPdf {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.QuotePdfModal__RenderPdf__Error {
    flex: 1;
}

.QuotePdfModal__RenderPdf__Loading {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.QuotePdfModal__RenderPdf__ContentFrame {
    width: 100%;
    border-color: white;
    border: 0;
    flex: 1;
}

.QuotePdfModal__RenderPdf__Footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    background-color: white;
    position: sticky;
    border-radius: 0 0 12px 12px;
    gap: 6px;

    .MyButton--Hollow {
        min-width: 128px;
    }

    @media #{$small-down} {
        bottom: 0;
        padding: 16px;
    }
    @media #{$medium-up} {
        bottom: -40px;
        padding: 16px 32px 32px;
    }
}
