@import '../../variables';

.SectionHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 12px;
    justify-content: space-between;
    padding-bottom: 6px;
    border-bottom: 1px solid $color-grey-lighter;

    &.SectionHeader--autoMargin {
        margin-top: 24px;
    }
}

.SectionHeader__TitleContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: baseline;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.SectionHeader__Title {
    font-size: 16px;
    font-weight: $font-weight-semi-bold;
    margin: 0;
}

.SectionHeader__TitleContext {
    display: flex;
    font-size: 16px;
    flex-direction: row;
    color: $color-grey-light;
    font-weight: $font-weight-normal;

    &:before {
        content: '|';
        margin-right: 6px;
    }
}

.SectionHeader__Content {
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-end;
}
