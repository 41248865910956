.Layout__Container--DealsPage--kanban {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
}

.DealsPage__DisplayToggle__IconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.DealsPage__Filters {
    padding-bottom: 12px;
}

.DealsPage__LayoutBody--kanban {
    flex: 1;
    overflow: hidden;
    min-height: 300px;
}
