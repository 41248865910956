@import '../../../../variables.scss';

.QuotePriceEditModal__Frame {
    width: 600px;
}

.QuotePriceEditModal__AmountField {
    .PropertyEdit__Label,
    .PropertyEdit__InputWrapper {
        height: 0;
        transition: height 0.3s;
    }

    &.visible {
        .PropertyEdit__Label,
        .PropertyEdit__InputWrapper {
            height: 52px;
        }
    }
}
