@import '../../variables.scss';

@keyframes modal_reveal {
    from {
        transform: translateY(100vh);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes modal_reveal_mobile {
    from {
        transform: translateX(100vh);
    }
    to {
        transform: translateX(0);
    }
}

.MyModal__ScrollContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    position: absolute;
    left: 0;
    width: 100%;

    @media #{$small-down} {
        top: 44px;
        height: calc(100% - 44px);
        padding: 0;
    }
    @media #{$medium-up} {
        top: 0;
        height: 100%;
        padding: 24px 0;
    }
}

.MyModal__MobileHeader {
    @media #{$medium-up} {
        display: none;
    }
    position: fixed;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 1;
    top: 0;
    width: 100%;
    background-color: $color-secondary-dark;
    color: white;
    height: 44px;
    animation: modal_reveal_mobile 0.2s ease-out;
}

.MyModal__MobileHeader__BackButton {
    color: white;
    padding: 0;
    min-width: 44px;
    height: 44px;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .MyButton__Inner__IconRight {
        font-size: 32px;
    }
}

.MyModal__MobileHeader__Title {
    font-size: 20px;
    font-weight: $font-weight-normal;
}

.MyModal__Frame {
    background-color: white;
    position: relative;
    margin: auto;
    outline: 0;

    // disable animation in storybook
    .sb-show-main & {
        animation: none !important;
    }

    @media #{$small-down} {
        padding: $modalFramePaddingSmall;
        min-width: 100%;
        max-width: 100%;
        min-height: calc(100vh - 44px);
        min-height: calc(100dvh - 44px);
        overflow: hidden;
    }
    @media #{$medium-up} {
        padding: $modalFramePaddingLarge;
        max-width: calc(100% - 112px);
        border-radius: 12px;
        box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2);

        &--full-height {
            min-height: $modal-full-height;
        }
    }
}

.MyModal__Frame--animate-in {
    @media #{$small-down} {
        animation: modal_reveal_mobile 0.2s ease-out;
    }
    @media #{$medium-up} {
        animation: modal_reveal 0.2s ease-out;
    }

    // disable animation in storybook
    .sb-show-main & {
        animation: none;
    }
}

.MyModal__Body {
    flex: 1;
}

.MyModal__LoadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
    flex: 1;

    .MuiLinearProgress-root {
        width: 100px;
    }
}

.MyModal__Error {
    flex: 1;

    @media #{$small-down} {
        padding: 64px 0;
    }
    @media #{$medium-up} {
        padding: 64px;
    }
}

.MyModal__Header {
    border-bottom: 1px solid $color-grey-lighter;

    @media #{$small-down} {
        margin: 0 -16px 16px;
        padding: 0 16px 16px 16px;
    }
    @media #{$medium-up} {
        padding-bottom: 24px;
        margin-bottom: 24px;
    }

    .PageHeader {
        flex: 1;
        margin-bottom: 0;
    }
}

.MyModal__CloseButton {
    @media #{$small-down} {
        display: none;
    }

    border: 0;
    min-width: 0;
    position: absolute;
    right: -52px;
    top: 0;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.8);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    height: auto;
    font-weight: normal;
    padding: 4px 8px;

    .MyButton__Inner__Label {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &:hover {
        background-color: transparent;
    }

    .icon {
        font-size: 28px;
        color: white;
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
    }

    // Hide close button on all but top-most modal
    .MyModal:not(:last-child) & {
        opacity: 0;
    }
}
