@import 'variables.scss';

.CustomerQuoteMain {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.CustomerQuoteMain__Title {
    margin: 0;
}

.CustomerQuoteMain__Summary {
    display: flex;
    justify-content: flex-start;
    gap: 32px;
    flex-wrap: wrap;

    @media #{$very-small-only} {
        flex-direction: column;
    }
}

.CustomerQuoteMain__Summary__TotalsTable {
    flex: 1;

    td {
        white-space: nowrap;
        border-bottom: 1px solid $color-grey-lighter;

        &:last-child {
            text-align: right;
            font-weight: $font-weight-semi-bold;
        }
    }
}

.CustomerQuoteMain__Summary__Content {
    flex: 1;
}

.CustomerQuoteMain__Summary__Content__Message {
    color: $color-grey-base;
    margin-bottom: 12px;
}

.CustomerQuoteMain__Summary__Content__Actions {
    border: 1px solid $color-grey-lighter;
    padding: 12px;
    border-radius: 9px;
    background-color: white;
}

.CustomerQuoteMain__PdfFrame {
    width: 100%;
    flex: 1;
    border-radius: 5px;
    overflow: hidden;
}
