@import 'variables.scss';

.Layout__Container {
    overflow: auto;
    height: 100vh;
    height: 100dvh;
    padding-bottom: 32px;
    overscroll-behavior: none;

    @media #{$medium-up} {
        // space for mainnav
        margin-left: $mainNavWidth;
    }

    // Set up variable for horizontal padding between the edge of the page and the content.
    @media #{$small-down} {
        --layoutPadding: 12px;
    }
    @media #{$medium-up} {
        --layoutPadding: 24px;
    }
}
