@import '../../variables.scss';

.MeasurementDisplay {
    display: inline-block;

    .unit.mm {
        font-size: 70%;
        font-weight: $font-weight-light;
    }
}
