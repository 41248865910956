@import '../../variables.scss';

.DataTablePaging {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
    padding: 8px 4px 8px 12px;

    // when inside a LayoutHeader adjust padding
    .LayoutHeader > & {
        margin-inline: calc(var(--layoutPadding) * -1);
        padding-left: var(--layoutPadding);
    }
}

.DataTablePaging__Page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.DataTablePaging__Page__PageNo {
    padding: 0 12px;
}

.MyButton.DataTablePaging__Page__PageButton {
    min-width: 0;
    padding: 2px;
}
