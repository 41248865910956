@import '../../variables';

.PageHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 24px;
    justify-content: space-between;
}

.PageHeader__TitleContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex: 1;
}

.PageHeader__TitleContainer__TitleWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 24px;
    font-weight: $font-weight-semi-bold;
    position: relative;
    margin: 0;
    overflow: hidden;
}

// A margin used to hold the separator character between title and titleContext
// When the titleContext wraps, the separator will be hidden
$titleMargin: 16px;
$titleSpacing: 8px;

.PageHeader__TitleContainer__Title {
    margin-right: $titleMargin;
    padding-right: $titleSpacing;
}

.PageHeader__TitleContainer__TitleContext {
    display: flex;
    flex-direction: row;
    color: $color-grey-light;
    font-weight: $font-weight-normal;
    margin-left: -$titleMargin;

    &:before {
        content: '|';
        margin-right: $titleSpacing;
    }
}

.PageHeader__TitleContainer__Subtitle {
    font-size: 14px;
    color: $color-grey-base;
    margin: 0;
}

.PageHeader__ContentContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
}
