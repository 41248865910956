@import '../../variables.scss';

.StatusPill {
    border-radius: 24px;
    font-weight: $font-weight-semi-bold;
    color: white;
    background-color: $color-grey-light;
    border: 1px solid $color-grey-light;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    padding: 0 12px;

    &--small {
        font-size: 12px;
    }
    &--medium {
        font-size: 14px;
    }
    &--large {
        font-size: 16px;
    }
}
