@import '../../../../variables.scss';

.ManufacturerOrderStatusBadge {
    display: flex;
    align-items: flex-start;
    gap: 6px;

    &.ManufacturerOrderStatusBadge--small {
        font-size: 13px;
    }
    &.ManufacturerOrderStatusBadge--large {
        font-size: 14px;
    }

    .icon {
        font-size: 16px;
    }
}

.ManufacturerOrderStatusBadge__Icon {
    font-size: 16px;
    display: flex;
    justify-content: center;
    position: relative;
    top: 1.5px;

    .CANCELLED & {
        color: $color-danger-base;
    }
    .COMPLETED & {
        color: $color-accent-base;
    }
    .IN_PROGRESS & {
        color: $color-primary-base;
    }
    .ON_HOLD & {
        color: $color-warning-dark;
        background-color: $color-warning-base;
        border-radius: 100%;
    }
    .UNSTARTED & {
        color: $color-grey-light;
    }
}
