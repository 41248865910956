@import '../../../../variables.scss';

.PurchaseOrderDetailModalFooter {
    display: flex;
    border-top: 1px solid #00000033;
    background-color: white;
    border-radius: 0 0 12px 12px;
    position: sticky;
    bottom: 0;
    z-index: 1;

    @media #{$very-small-only} {
        flex-direction: column;
        justify-content: space-between;
        gap: 12px;
        align-items: center;
    }
    @media #{$small-up} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
    }

    @media #{$small-down} {
        padding: 12px 16px;
        margin-inline: -16px;
    }
    @media #{$medium-up} {
        bottom: -40px;
        margin-inline: -32px;
        padding: 16px 32px 32px;
    }
}

.PurchaseOrderDetailModalFooter__Content {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;

    @media #{$very-small-only} {
        justify-content: center;
    }
}

.PurchaseOrderDetailModalFooter__Content__Price {
    font-size: 20px;
    margin-left: 2px;
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;
    gap: 6px;
}

.PurchaseOrderDetailModalFooter__Content__Price__EditButton {
    min-width: 0;
    padding: 4px;
}
