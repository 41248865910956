@import '../../variables.scss';

.PropertyEditNumpad__Button {
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 200px;
    padding: 0;

    &:focus {
        border: 1px solid $color-primary-base;
        outline: 1px solid $color-primary-base;
        background-color: white;
    }
    &:hover {
        background-color: white;
    }

    .MyButton__Inner {
        height: 100%;
    }
    .MyButton__Inner__Label {
        height: 100%;
        display: flex;
    }
    &:disabled {
        background-color: white;
    }
}

.PropertyEditNumpad__Button__Value {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 16px;
    font-weight: $font-weight-normal;
    max-width: 150px;
}

.PropertyEditNumpad__Button__Icon {
    background-color: white;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-grey-dark;

    svg {
        width: 18px;
        height: 18px;
    }

    .PropertyEditNumpad__Button:disabled & {
        color: $color-grey-light;
    }
}
