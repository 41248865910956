.PropertyEditMoney {
    .MyTextInput__LeftIcon {
        width: 16px;
        height: 16px;
    }

    .MuiInputBase-input {
        min-width: 120px;

        appearance: textfield;
        &::-webkit-inner-spin-button {
            display: none;
        }
    }
}
