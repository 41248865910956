@import '../../../../variables.scss';

.EditSection {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: white;
    border-radius: 5px;
    padding: 24px;

    & + .EditSection {
        margin-top: 24px;
    }
}

.EditSection__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.EditSection__Header__Title {
    margin: 0;
    font-size: 18px;
    color: $color-secondary-dark;
}

.EditSection__Body {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.EditSection__Footer {
    display: flex;
    flex-direction: row-reverse;
    gap: 6px;

    .MyButton {
        min-width: 120px;
    }
}
