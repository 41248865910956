@import 'variables';

.DealPayments__Date {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.DealPayments__Description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.DealPayments .SectionHeader__TitleContainer {
    align-items: center;
}

.DealPayments__ShowArchived {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin: -8px 0;
}
