@import '../../../../variables.scss';

.ProductPathDisplay {
    color: rgba(0, 0, 0, 0.5);

    svg {
        vertical-align: middle;
    }
    strong {
        font-weight: $font-weight-normal;
        color: black;
    }
}
