@import '../variables.scss';

.AppUpdater__UpdateDialog {
    position: fixed;
    z-index: 9999;
    max-width: calc(100% - 48px);

    color: white;
    background: $color-secondary-dark;
    border-radius: 5px;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);

    animation: appUpdater-dialog-slide-in 0.5s ease;
    transform: translateY(0);
    transition: padding 0.5s ease, width 0.5s ease, min-height 0.5s ease;

    @media #{$medium-down} {
        bottom: 4px;
        right: 4px;
    }
    @media #{$large-only} {
        bottom: 24px;
        right: 24px;
    }
    &.layout-default {
        padding: 16px 24px;
        width: 375px;
        min-height: 133px;
    }
    &.layout-minimized {
        width: 221px;

        @media #{$medium-down} {
            padding: 4px 6px;
            min-height: 28px;
        }
        @media #{$large-only} {
            padding: 8px 12px;
            min-height: 37px;
        }
    }
}

.AppUpdater__UpdateDialog__ContentDefault {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
}
.AppUpdater__UpdateDialog__ContentDefault__Title {
    font-weight: $font-weight-semi-bold;
}

.AppUpdater__UpdateDialog__ContentDefault__Message {
    font-size: 12px;
}

.AppUpdater__UpdateDialog__ContentDefault__Actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
}

.AppUpdater__UpdateDialog__ContentMinimized {
    display: flex;
    align-items: center;
    animation: appUpdater-dialog-minimized-fade-in 1s ease;
    font-size: 12px;
    .icon {
        margin-right: 6px;
        font-size: 20px;
    }
}

@keyframes appUpdater-dialog-slide-in {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes appUpdater-dialog-minimized-fade-in {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
