@import '../../variables.scss';

.MyButton {
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
    flex-wrap: nowrap;
    text-align: center;

    .MuiCircularProgress-root {
        color: currentColor;
        margin-right: 12px;
    }

    // default / unstyled
    background-color: transparent;
    color: black;
    height: 40px;
    font-size: 14px;
    font-weight: $font-weight-semi-bold;
    border-radius: 4px;
    overflow: hidden;
    padding: 16px 20px;

    transition: background-color 0.15s;
    &:hover {
        color: '#FFF';
        background-color: $color-primary-lightest;
    }
    &.Mui-disabled {
        background-color: $color-grey-lighter;
        color: $color-grey-light;

        .MuiCircularProgress-root {
            color: $color-grey-base;
            opacity: 0.8;
        }
    }

    // Sizes
    &.MyButton--large {
        height: 52px;
        font-size: 14px;
    }

    &.MyButton--medium {
        height: 40px;
        font-size: 14px;
    }

    &.MyButton--small {
        height: 28px;
        font-size: 12px;
        padding: 0 12px;
    }

    // Styles
    // Primary - Solid bright blue
    &.MyButton--Primary {
        color: #fff;
        background-color: $color-primary-base;

        &:hover {
            color: '#FFF';
            background-color: lighten($color-primary-base, 10%);
        }

        &.Mui-disabled {
            background-color: $color-grey-lighter;
            color: $color-grey-light;
        }
    }

    // Secondary - Solid dark blue
    &.MyButton--Secondary {
        color: #fff;
        background-color: $color-secondary-dark;

        &:hover {
            color: '#FFF';
            background-color: lighten($color-secondary-dark, 10%);
        }

        &.Mui-disabled {
            background-color: $color-grey-lighter;
            color: $color-grey-light;
        }
    }

    // Hollow - Outlined dark blue
    &.MyButton--Hollow {
        background-color: white;
        color: $color-secondary-dark;
        box-shadow: inset 0 0 0 1px $color-secondary-base;

        &:hover {
            background-color: darken(white, 10%);
        }

        &.Mui-disabled {
            box-shadow: inset 0 0 0 2px $color-grey-lighter;
            background-color: $color-grey-lighter;
            color: $color-grey-light;
        }
    }

    // Accent - Solid Green
    &.MyButton--Accent {
        color: rgba(0, 0, 0, 0.7);
        background-color: $color-accent-base;

        &:hover {
            color: '#FFF';
            background-color: lighten($color-accent-base, 10%);
        }

        &.Mui-disabled {
            background-color: $color-grey-lighter;
            color: $color-grey-light;
        }
    }

    // Danger - Solid red
    &.MyButton--Danger {
        background-color: $color-danger-base;
        color: white;

        &:hover {
            background-color: lighten($color-danger-base, 10%);
        }

        &.Mui-disabled {
            background-color: $color-grey-lighter;
            color: $color-grey-light;
        }
    }

    // LinkButton - no outline, looks like a blue link
    &.MyButton--LinkButton {
        background-color: transparent;
        color: $color-primary-base;
        padding: 0;
        font-weight: inherit;
        height: auto;
        min-width: 0;
        border-radius: 0;
        border-bottom: 1px solid transparent;
        margin-bottom: -1px;

        &:hover {
            // text-decoration: underline;
            border-bottom: 1px solid $color-primary-base;
        }

        &.Mui-disabled {
            color: $color-grey-light;
            text-decoration: none !important;
        }

        .MyButton__Inner {
            gap: 2px;
        }

        .MyButton__Inner__IconLeft {
            opacity: 1;
            font-size: 16px;
        }
    }

    // Nude - no outline, looks like regular text
    &.MyButton--Nude {
        background-color: transparent;
        color: black;
        font-weight: inherit;

        &.Mui-disabled {
            color: $color-grey-light;
        }
    }
}

.MyButton__Inner {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0 12px;
}

.MyButton__Inner__Label {
    flex: 1;
}

.MyButton__Inner__IconLeft {
    font-size: 20px;
    opacity: 0.4;

    &.Loading {
        padding: 4px;
    }
}
.MyButton__Inner__IconRight {
    font-size: 24px;
}
