@import '../../variables.scss';

.PropertyEdit {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
}

.PropertyEdit__Label {
    font-size: 14px;
    color: $color-grey-base;
}

.PropertyEdit__Label__Text__InlineHint {
    font-size: 12px;
}

.PropertyEdit__Label__Hint {
    font-size: 12px;
    color: $color-grey-base;
    display: block;
}

.PropertyEdit__InputWrapper {
    .MuiInputBase-root {
        background-color: white;
    }
}

.PropertyEdit__InputWrapper--fullWidth {
    width: 100%;

    .MuiFormControl-root {
        width: 100%;
    }
}
