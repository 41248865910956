@import '../../../../variables.scss';

$dealModalSideBarWidth: 120px;

.DealDetailModal__Frame {
    width: 950px;
}

.DealDetailModal__Header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-bottom: 0;
    margin-bottom: 0;
}

.DealDetailModal__PageHeader__TitleContext {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-wrap: wrap;
}

.DealDetailModal__PageHeader__Tuid {
    white-space: nowrap;
}

.DealDetailModal__Warning__Note {
    font-size: 12px;
    color: $color-warning-dark;
}

.DealDetailModal__Content {
    @media #{$medium-up} {
        // show flags in side bar
        display: flex;
        gap: 24px;
    }
}

.DealDetailModal__Content__Main {
    flex: 1;

    @media #{$medium-up} {
        // prevent growing outside of the frame
        overflow: hidden;
    }
}

.DealDetailModal__Details {
    position: relative;
}

.DealDetailModal__Details__Status {
    display: flex;
    gap: 6px;
    align-items: center;
}

.DealDetailModal__Flags {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 0;

    @media #{$small-down} {
        // appears in block below the details
        margin-top: 24px;
    }

    .PropertyContainer {
        width: 100%;
        gap: 0;
    }

    .PropertyDisplay {
        padding-inline: 12px;
        padding-top: 4px;
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .PropertyDisplay__Label {
        font-size: 12px;
    }
    .PropertyDisplay__Value__Text {
        align-items: center;
        display: flex;
    }
}

// Notes
.DealDetailModal__Notes__List {
    margin-bottom: 12px;
}

.DealDetailModal__Note {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &:not(:last-child) {
        padding-bottom: 12px;
        border-bottom: 1px solid $color-grey-lighter;
        margin-bottom: 12px;
    }
}

.DealDetailModal__Note__Content {
    white-space: pre-wrap;
}

.DealDetailModal__Note__Footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0 6px;
    font-size: 12px;
}

.DealDetailModal__Note__Footer__Author {
    color: $color-secondary-base;
}

.DealDetailModal__Note__Footer__Date {
    color: $color-grey-base;
}

.DealDetailModal__Note__Footer__EditButton {
    padding: 4px;
    min-width: 0;
    height: auto;

    .MyButton__Inner__IconLeft {
        font-size: 16px;
        color: $color-grey-base;
    }
}

.DealDetailModal__Notes__Empty {
    color: $color-grey-light;
    margin-top: -8px;
}

.DealDetailModal__Tabs {
    margin-top: 24px;
}
