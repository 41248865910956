@import '../../../../variables.scss';

.BundleTemplateFieldsEditModal__Frame {
    width: 642px;
}

.BundleTemplateFieldsEditModal__TemplateField__Field {
    .PropertyEditNumber__Input,
    .PropertyEdit__InputWrapper {
        width: 100%;
    }
}
