@import '../../variables.scss';

.MyMultiSelectInput {
    // label
    .MuiInputLabel-root {
        transform: translate(16px, 18px);
        pointer-events: none;
        &.MuiInputLabel-shrink {
            transform: translate(16px, 8px) scale(0.75);
        }
        &.Mui-focused {
            color: $color-primary-dark;
        }
    }

    &.MyMultiSelectInput--no-label {
        .MuiInputBase-input {
            padding: 15px 32px 15px 16px;
        }
    }

    // input
    .MuiOutlinedInput-root {
        background-color: white;

        &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                border-color: $color-primary-base;
            }
        }
    }
}
// menu
.MyMultiSelectInput__Menu {
    .MuiMenuItem-root {
        &.Mui-selected {
            background-color: $color-primary-base;
            color: white;
        }
    }
    .MuiMenu-paper {
        transition-duration: 0.1s !important;
    }
}
