@import '../../variables.scss';

.PropertyEditAddress__InputWrapper {
    width: 100%;
}

.PropertyEditAddress__TextInputContainer {
    position: relative;
    width: 100%;
}

.PropertyEditAddress__LoadingSpinner {
    position: absolute;
    right: 36px;
    top: 9px;
}

.PropertyEditAddress__SelectedAddressContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    position: relative;
}

.PropertyEditAddress__SelectedAddress {
    background-color: $color-grey-lightest;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 5px 12px;
    min-height: 40px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.PropertyEditAddress__SelectedAddress__ClearButton {
    padding: 0;
    min-width: 0;

    .MyButton__Inner__IconRight {
        font-size: 20px;
    }
}

.PropertyEditAddress__SelectedAddress__CheckIcon {
    color: $color-checked-green;
    font-size: 20px;
}

.PropertyEditAddress__PredictionsContainer {
    position: relative;
    z-index: 1;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: 0;
    border-radius: 0 0 5px 5px;
}

.PropertyEditAddress__PredictionsContainer__Empty {
    color: $color-grey-light;
    padding: 6px 12px;
}

.PropertyEditAddress__PlacePredictionItem {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: flex-start;
    text-align: left;
    gap: 8px;
    font-family: $fontFamily;
    cursor: pointer;

    &:hover {
        background-color: $color-secondary-light;
    }

    .icon {
        min-width: 16px;
        top: 3px;
        position: relative;
        color: $color-grey-light;
    }

    .description {
        flex: 1;
    }
}
