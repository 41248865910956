@import 'variables';

.DealPaymentStatusBadge {
    &.PENDING {
        background-color: $color-grey-light;
        border-color: $color-grey-light;
        color: white;
    }
    &.PAID {
        background-color: $color-checked-green;
        border-color: $color-checked-green;
        color: white;
    }
    &.CANCELLED {
        background-color: $color-danger-base;
        border-color: $color-danger-base;
        color: white;
    }
}
