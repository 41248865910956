@import '../../../../variables.scss';
@import '../../../../components/MessagePanel/MessagePanel.scss';

.DashboardSectionWorkflow__Messages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.DashboardSectionWorkflow__MessageLink {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    @extend .MessagePanel;

    &--warning {
        @extend .MessagePanel--warning;
    }

    &--info {
        @extend .MessagePanel--info;
    }
}

.DashboardSectionWorkflow__MessageLink__Count {
    color: white;
    font-weight: $font-weight-bold;
    margin-block: -1px;
    width: auto;
    min-width: 22px;
    height: 22px;
    border-radius: 12px;
    padding-inline: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    .DashboardSectionWorkflow__MessageLink--warning & {
        background-color: $color-warning-dark;
        border: 1px solid $color-warning-dark;
    }
    .DashboardSectionWorkflow__MessageLink--info & {
        background-color: $color-primary-base;
        border: 1px solid $color-primary-base;
    }
}
