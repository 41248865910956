@import '../../variables.scss';

.MyEditModal {
    .PageHeader {
        margin: 0;
    }
}

.MyEditModal__Frame {
    padding: 0;
    display: flex;
}

.MyEditModal__Body {
    overflow: hidden;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: $modal-full-height;
}

.MyEditModal__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-grey-lighter;

    @media #{$small-down} {
        padding: 16px;
    }
    @media #{$medium-up} {
        padding: 32px 32px 24px;
    }
}

.MyEditModal__Header__PageHeader {
    min-height: 40px;
}

.MyEditModal__FieldsWrapper {
    overflow-y: auto;
    flex: 1;

    @media #{$small-down} {
        padding: 24px 16px;
    }
    @media #{$medium-up} {
        padding: 32px;
    }
}

.MyEditModal__Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 32px 32px;
    border-top: 1px solid $color-grey-lighter;
}

.MyEditModal__Footer__Primary,
.MyEditModal__Footer__Secondary {
    display: flex;
    gap: 6px;
}

.MyEditModal__Footer__Primary__Cancel {
    min-width: 120px;
}

.MyEditModal__Footer__Primary__Save {
    max-width: 0;
    min-width: 0;
    overflow: hidden;
    transition: max-width 0.2s ease, min-width 0.2s ease;
    margin-left: -6px; // cancel out flex gap when hidden

    &.reveal {
        margin: 0;
        max-width: 300px;
        min-width: 120px;
    }
}

.MyEditModal--with-tabs {
    .MyEditModal__Header {
        border: 0;
        padding-bottom: 12px;
        min-height: 84px;
    }
    .MyEditModal__FieldsWrapper {
        padding-top: 0;
    }
    .MyTabs__Tabs {
        margin-bottom: 32px;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 10;
    }
    .MyTabs__TabPanel {
        padding: 0 12px;
    }
}
