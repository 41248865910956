@import '../../variables.scss';

.PropertyContainer--column {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &.PropertyContainer--full-width-inputs {
        .PropertyEdit,
        .MuiFormControl-root {
            width: 100%;
        }
    }
}

.PropertyContainer--row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px 24px;

    flex-wrap: wrap;

    &.PropertyContainer--spread-row {
        .PropertyEdit,
        .PropertyDisplay {
            flex: 1;
        }
    }
    .PropertyDisplay__Label,
    .PropertyDisplay__Value {
        white-space: nowrap;
    }
    .PropertyEdit__InputWrapper {
        width: 100%;
    }
    .MyTextInput {
        width: 100%;
    }
}

.PropertyContainer--table {
    display: table;

    .PropertyDisplay,
    .PropertyEdit {
        display: table-row;
    }

    .PropertyEdit__Label,
    .PropertyDisplay__Label {
        display: table-cell;
        padding: 4px 0;
        padding-right: 24px;
        white-space: nowrap;
        vertical-align: middle;
    }

    .PropertyDisplay__Label__Hint,
    .PropertyEdit__Label__Hint {
        white-space: initial;
    }
    .PropertyEdit__InputWrapper,
    .PropertyDisplay__Value {
        display: table-cell;
        padding: 4px 0;
    }

    .PropertyDisplay--verticalAlign-top,
    .PropertyEdit--verticalAlign-top {
        .PropertyEdit__Label,
        .PropertyDisplay__Label,
        .PropertyEdit__InputWrapper,
        .PropertyDisplay__Value {
            vertical-align: top;
        }
    }
}

.PropertyContainerSpacer {
    margin-top: 24px;
}

.PropertyContainer--with-input-spacing {
    .PropertyEdit__InputWrapper,
    .PropertyDisplay__Value {
        // match standard input height
        // this works as min height in table layouts
        height: 40px;
        vertical-align: middle;
    }

    &.PropertyContainer--table {
        .PropertyDisplay--verticalAlign-top,
        .PropertyEdit--verticalAlign-top {
            .PropertyDisplay__Label__Text,
            .PropertyDisplay__Value__Text,
            .PropertyEdit__Label__Text {
                display: inline-block;
                padding-top: 12px;
            }
        }
    }
}
.PropertyContainer--indent {
    padding: 0 0 0 32px;
    border-left: 8px solid $color-secondary-light;
    margin-bottom: 24px;
}
