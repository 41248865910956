@import '../../../../variables.scss';

.OrderProductEditModal__Frame {
    width: 600px;

    .PageHeader {
        min-height: 0;
    }
    .PageHeader__TitleContainer__Title {
        font-size: 20px;
    }
}
