@import '../../../../variables.scss';

.QuoteProductList {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.QuoteProductList__Empty {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding-bottom: 24px;
}

.QuoteProductList__Toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    padding: 8px 0;

    .FilterStrip {
        width: 100%;
    }

    .FilterStrip__Field {
        flex: 1 1 0;
        .MyTextInput,
        .MySelectInput,
        .MyAutocompleteInput {
            width: 100%;
        }
    }
    .FilterStrip__Field--SearchFilter {
        flex: 2 1 0;
    }
    // group filter
    .FilterStrip__Field--SelectFilter {
        flex: 3 1 0;
    }
}

.QuoteProductList__Filters__SupplierOrderOption {
    display: flex;
    gap: 8px;
    align-items: center;
}

.QuoteProductList__ProductsFilteredOut {
    color: $color-grey-base;
    text-align: center;
    padding: 40px 0;
}

.QuoteProductList__Products {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-inline: -32px;
    .DataTable {
        flex: 1;
    }
    .DataTable__Table {
        margin-bottom: 24px;
        margin-inline: 32px;
    }

    @media #{$medium-up} {
        // gradient fades left and right as user scrolls
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 32px;
            bottom: 24px;
            z-index: 1;
            background-image: linear-gradient(
                to right,
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 0)
            );
        }
        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 32px;
            bottom: 24px;
            z-index: 1;
            background-image: linear-gradient(
                to right,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 1)
            );
        }
    }
}

.QuoteProductList__OptionsDisplay {
    min-width: 180px;
}

.QuoteProductList__OptionsDisplay__Options {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.QuoteProductList__OptionsDisplay__Errors {
    color: $color-danger-base;
    font-weight: $font-weight-semi-bold;
}

.QuoteProductList__ProductDisplay__Brand {
    color: $color-grey-base;
}
