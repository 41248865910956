.MyPdfViewer {
    .rpv-core__viewer {
        height: auto !important;
    }
    .rpv-core__inner-page {
        background-color: white;
    }

    .rpv-core__page-layer::after {
        box-shadow: none;
    }

    &.loading {
        .rpv-core__inner-page {
            background-color: transparent;
        }
    }
}
