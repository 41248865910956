@import 'variables.scss';
.MainNav {
    .MuiDrawer-paper {
        width: $mainNavWidth;
        background-color: $color-secondary-dark;
        color: white;
        align-items: flex-start;
        padding: 16px 0;
        gap: 12px 0;
        z-index: 2;

        // hide scroll bar
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Chrome, Safari and Opera */
        }
    }
}

.MainNav__Logo {
    padding: 0 20px;
}
.MainNav__Logo__DefaultImage {
    height: 24px;
}
.MainNav__Logo__HostedImage {
    width: 100%;
    object-fit: contain;
}

.MainNav__LoadingProgress {
    margin-left: 16px;
}

.MainNav__Nav {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-right: 8px;
}

.MainNav__Nav__TopSection {
    flex: 1 1;
}
.MainNav__Nav__BottomSection {
    position: sticky;
    bottom: 0;
    padding-bottom: 40px;
}

.MainNav__NavSection {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.MainNav__NavSection__Title {
    color: rgba(255, 255, 255, 0.6);
    font-weight: $font-weight-semi-bold;
    text-transform: uppercase;
    padding: 4px 20px;
}

.MainNav__NavLinkItem__Link {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding: 10px 20px;
    color: white;
    border-radius: 0 20px 20px 0;

    &:hover {
        background-color: $color-secondary-darker;
    }
    &.active {
        background: linear-gradient(45deg, #26c6da, #0288d1);
    }

    .icon {
        font-size: 18px;
        position: relative;
        top: -1px;
    }

    .label {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    small {
        background-color: $color-warning-base;
        color: $color-warning-darker;
        border-radius: 12px;
        margin: 0 4px;
        padding: 2px 8px;
        font-size: 11px;
    }
}

.MainNav__NavSection--Settings {
    position: sticky;
    bottom: 0;
}
