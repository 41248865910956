@import '../../../../variables.scss';

.SupplierOrderModal__Header {
    display: block;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
}

.SupplierOrderModal__Frame {
    width: 800px;

    @media #{$medium-up} {
        min-height: 500px;
    }
}

.SupplierOrderModal__Status {
    display: flex;
    align-items: center;
    gap: 6px;
}

.SupplierOrderModal__HistoryTab {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media #{$medium-up} {
        min-height: 328px;
    }
}

.SupplierOrderModal__HistoryTab__Loading {
    display: flex;
    justify-content: center;
    padding: 24px;
}

.SupplierOrderModal__HistoryTab__Empty {
    display: flex;
    justify-content: center;
    color: $color-grey-base;
    padding: 24px;
}
