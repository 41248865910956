@import '../../../../variables.scss';

$fabricTableOffset: 176px;

.ProductOptionDisplayGrid__Option {
    line-height: 1;

    &:not(:first-child) {
        margin-top: 6px;
    }
}

.ProductOptionDisplayGrid__Option__Price {
    white-space: nowrap;
    float: right;
    font-size: 12px;
    line-height: 14px;
}

.ProductOptionDisplayGrid__OptionFabric {
    display: table;
    width: calc(100% + #{$fabricTableOffset});
    font-size: 13px;
    line-height: 1;
    margin-top: 6px;
    margin-left: -$fabricTableOffset;
    color: $color-secondary-base;
}

.ProductOptionDisplayGrid__FabricDetail {
    display: table-row;

    .label {
        display: table-cell;
        width: $fabricTableOffset;
        padding-right: 6px;
        opacity: 0.7;
    }

    .value {
        display: table-cell;
        padding-block: 2px;
    }
}
