@import '../../../../variables.scss';

.QuoteDetailModal__Frame {
    width: 1100px;

    &:not(.MyModal__Frame--Loading) {
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
    }
}

.QuoteDetailModal__Header {
    display: block;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
}

.QuoteDetailModal__BreadcrumbWrapper {
    display: flex;
    gap: 4px;
    align-items: center;

    .icon {
        color: $color-grey-base;
    }
}
.QuoteDetailModal__Breadcrumb {
    font-size: 14px;
    color: $color-primary-base;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.QuoteDetailModal__Header {
    margin-bottom: 18px;
}

.QuoteDetailModal__PageHeader__TitleContext {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-wrap: wrap;
}

.QuoteDetailModal__PageHeader__Tuid {
    white-space: nowrap;
}

.QuoteDetailModal__ArchivedBadge {
    font-size: 16px;
    background-color: $color-warning-base;
    color: $color-warning-darker;
    border-radius: 12px;
    padding: 0 12px;
}

.QuoteDetailModal__Body {
    display: flex;
    flex-direction: column;
}

.QuoteDetailModal__SummaryPanel__OriginalPrice {
    text-decoration: line-through;
}

.QuoteDetailModal__SummaryPanel__Price {
    margin-left: 2px;
    font-weight: $font-weight-bold;
}

.QuoteDetailModal__DetailsPanel {
    .PropertyContainer {
        width: 100%;
        .PropertyDisplay__Label {
            width: 100px;
        }
    }
}
.QuoteDetailModal__DetailsPanel__EditButton {
    float: right;
}

.QuoteDetailModal__OrderUpdatesMessagePanel {
    margin-top: 12px;
}

.QuoteDetailModal__Footer {
    display: flex;
    border-top: 1px solid #00000033;
    background-color: white;
    border-radius: 0 0 12px 12px;
    position: sticky;
    bottom: 0;
    z-index: 1;

    @media #{$very-small-only} {
        flex-direction: column;
        justify-content: space-between;
        gap: 12px;
        align-items: center;
    }
    @media #{$small-up} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
    }

    @media #{$small-down} {
        padding: 12px 16px;
        margin-inline: -16px;
    }
    @media #{$medium-up} {
        bottom: -40px;
        margin-inline: -32px;
        padding: 16px 32px 32px;
    }
}

.QuoteDetailModal__Footer__Content {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;
    justify-content: center;
}

.QuoteDetailModal__Footer__Content__OriginalPrice {
    text-decoration: line-through;
    font-size: 16px;
}

.QuoteDetailModal__Footer__Content__Price {
    font-size: 20px;
    margin-left: 2px;
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;
    gap: 6px;
}

.QuoteDetailModal__Footer__Content__Price__EditButton {
    min-width: 0;
    padding: 4px;
}

.QuoteDetailModal__Footer__AcceptWrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;

    .errorIcon {
        color: $color-danger-base;
        font-size: 24px;
        line-height: 17px;
    }
}
