@import '../../../../variables.scss';

.DealNoteEditModal__Frame {
    width: 600px;
}

.DealNoteEditModal__Body {
    .MyEditModal__FieldsWrapper {
        padding: 0;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}

.DealNoteEditModal__ContentTextInput {
    width: 100%;
    flex: 1;

    .MuiInputBase-root {
        align-items: flex-start;
        flex: 1;
        border-radius: 0;
        background-color: white;
        padding: 24px 32px;
    }

    fieldset {
        border: 0;
    }

    textarea {
        min-height: 200px;
    }

    & + .FieldValidator__ErrorWrapper {
        padding: 12px 32px;
        position: absolute;
        bottom: 0;
    }
}
