@import '../../../../variables.scss';

.DealQuotesTab__Topbar {
    margin-bottom: 12px;
}

.DealQuotesTab__Filters {
    margin-bottom: 12px;
}

.DealQuotesTab__DataTable {
    @media #{$small-down} {
        // fill frame by negating modal padding
        margin-inline: -$modalFramePaddingSmall;
    }
}

.DealQuotesTab__StatusCell {
    display: flex;
    flex-direction: row;
    gap: 4px;
}
