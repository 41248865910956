@import '../../../../variables.scss';

.WorkflowPhaseBadge {
    display: flex;
    gap: 6px;

    &.WorkflowPhaseBadge--small {
        font-size: 13px;
    }
    &.WorkflowPhaseBadge--large {
        font-size: 14px;
    }

    .icon {
        font-size: 16px;
    }
}
