@import '../../variables';
.AppHeader {
    padding: 6px 12px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $topBarHeight;
    margin-bottom: 24px;
    position: sticky;

    @media #{$small-down} {
        // sticky to top and left
        top: 0;
        left: 0;
        z-index: 2;
    }
    @media #{$medium-up} {
        // sticky to left only
        left: 0;
    }
}

.AppHeader__NavLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.AppHeader__NavLeft__MobileMenuButton {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    min-width: 40px;
    padding: 0;
}

.AppHeader__NavLeft__Logo--DefaultImage {
    height: 24px;
}

.AppHeader__NavLeft__Logo--HostedImage {
    height: 48px;
    object-fit: contain;
}

.AppHeader__NavLeft__MockBadge {
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 12px;
    padding: 0px 12px;
    white-space: nowrap;
    color: #652300;
    background-color: #ff8b32;
    border: 1px solid #cd4800;

    @media #{$small-down} {
        font-size: 14px;
    }
    @media #{$medium-up} {
        font-size: 16px;
    }
}

.AppHeader__NavLeft__MockBadge__TooltipContent {
    font-size: 12px;
}

.AppHeader__NavRight {
    display: flex;
    flex-direction: row-reverse;
    gap: 6px;
}

.AppHeader__NavRight__UserMenuButton {
    .MuiButton-root {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        min-width: 0;
        padding: 0;
    }
}

.MuiButton-root.AppHeader__NavRight__ZohoChat {
    text-align: left;
    padding-left: 8px;
    padding-right: 12px;

    .MyButton__Inner__Label {
        line-height: 100%;
        color: $color-primary-base;
        position: relative;
        top: 2px;
    }

    .MyButton__Inner__IconLeft {
        font-size: 28px;
        color: $color-primary-base;
        opacity: 1;
    }
    .subtext {
        font-size: 10px;
        font-weight: $font-weight-normal;
        color: $color-grey-base;
    }
}

.AppHeader__NavRight__ZohoChat:hover {
    &.MuiButton-root {
        background-color: $color-grey-lighter;
    }
}

.AppHeader__NavRight__UserMenuButton__Menu {
    min-width: 150px;
}
