@import '../../../../variables.scss';

.OrderProductAddModal__Frame {
    width: 900px;

    .PageHeader {
        min-height: 0;
    }
    .PageHeader__TitleContainer__Title {
        font-size: 20px;
    }
}

.OrderProductAddModal__Filters {
    .FilterStrip__Field {
        width: 100%;
        .MyTextInput {
            width: 100%;
        }
    }
}

.OrderProductAddModal__ProductList {
    margin-top: 12px;
}

.OrderProductAddModal__ProductList__Loading,
.OrderProductAddModal__ProductList__Empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 16px;
}

.OrderProductAddModal__ProductList__Header {
    color: $color-grey-base;
    padding: 6px 0;
    margin-top: 12px;

    svg {
        vertical-align: middle;
    }
}

.OrderProductAddModal__ProductButton {
    padding: 8px 16px;
    font-weight: $font-weight-normal;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    text-align: left;
    white-space: unset;
    height: auto;
    border-radius: 0;
    background-color: $color-secondary-lighter;

    &:nth-child(odd) {
        &:not(:hover) {
            background-color: $color-secondary-lightest;
        }
    }
}

.OrderProductAddModal__ProductFormModal__Frame {
    width: 600px;

    .PageHeader {
        min-height: 0;
    }
    .PageHeader__TitleContainer__Title {
        font-size: 20px;
    }
}
