@import '../../../../variables.scss';

.SupplierSettingsEditable {
    margin-bottom: 24px;
    max-width: 1000px;

    .PropertyDisplay__Label,
    .PropertyEdit__Label {
        width: 150px;
    }

    .MySelectInput {
        width: 100%;
    }
}

.SupplierSettingsEditable__Email {
    word-break: break-all;
}
