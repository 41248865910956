@import '../../../../variables.scss';

.DealerSettingsEditable {
    max-width: 1000px;
}

.DealerSettingsEditable__CompanyLogo {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.MuiButton-root.DealerSettingsEditable__CompanyLogo__ImageButton {
    background-color: transparent;
    border-radius: 5px;
    width: 200px;
    padding: 16px;
    height: auto;
    min-height: 40px;

    &.editing {
        border: 1px solid $color-grey-light;
    }

    img {
        max-width: 184px;
        max-height: 184px;
        margin: 8px;
    }
}

.DealerSettingsEditable__CompanyLogo__NoImage {
    font-size: 13px;
    color: $color-grey-base;
    font-weight: $font-weight-normal;
}

.MyButton.DealerSettingsEditable__CompanyLogo__ClearButton {
    padding: 0;
    min-width: 0;
    padding: 8px;
    height: auto;
}

.DealerSettingsEditable__BindingTextHtml {
    .PropertyDisplay__Value {
        padding: 0 16px;
        border-left: 6px solid $color-grey-lighter;
        font-size: 12px;
        white-space: normal;
    }
}
