@import '../../../../variables.scss';

.OrderProductModal__Frame {
    width: 600px;
}

.OrderProductModal__Header {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
}

.OrderProductModal__PageHeader {
    align-items: flex-start;
    gap: 24px;

    .PageHeader__TitleContainer {
        min-height: 40px;
        justify-content: center;
        margin-bottom: 12px;
    }
}

.OrderProductModal__Section {
    position: relative;

    @media #{$small-down} {
        margin: 0 -16px;
        padding: 24px 16px;
    }
    @media #{$medium-up} {
        padding: 24px 0;
    }

    .PropertyDisplay__Label {
        white-space: unset;
        width: 160px;
    }
}

.OrderProductModal__Section__Header {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 8px;

    h2 {
        font-size: 18px;
        font-weight: $font-weight-semi-bold;
        margin-top: 0;
        margin-bottom: 0;
        line-height: 32px;
    }
}

.OrderProductModal__QuantityBadge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $color-secondary-light;
    background-color: $color-secondary-dark;
    color: white;
    padding: 4px 6px;
    min-width: 24px;
    height: 24px;
    border-radius: 24px;
    font-weight: $font-weight-semi-bold;
}

.OrderProductModal__NoOptions {
    color: $color-grey-base;
}
