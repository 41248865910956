@import '../../variables.scss';

.body--LoginPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    @media #{$small-down} {
        background-color: white;
    }
    @media #{$medium-up} {
        background-color: var(--override-login-background-color, $color-primary-base);
    }
}

.LoginPage {
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 32px;

    @media #{$small-down} {
        width: 100vw;
    }
    @media #{$medium-up} {
        width: 512px;
    }
}

.LoginPage--LoggingOut {
    background-color: transparent;
    width: auto;
    gap: 24px;
    color: $color-secondary-light;
    animation: LoggingOutFadeIn 1.5s linear;
}

@keyframes LoggingOutFadeIn {
    0% {
        opacity: 0;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.LoginPage__Logo {
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
    margin-bottom: 16px;
}

.LoginPage__ForgotWrapper {
    text-align: center;
}
