@import '../../../../variables.scss';

.PurchaseOrderCreateModal__Frame {
    width: 600px;
}

.PurchaseOrderCreateModal__MessagePanel {
    margin-top: -12px;
    margin-bottom: 24px;
}
