@import 'variables.scss';

.DealWarningIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    height: 16px;
    color: $color-warning-dark;
}
