@import '../../../../variables.scss';

.OrderAttachments__AttachmentsTable {
    overflow-x: auto;
    scrollbar-width: thin;
    margin-inline: -16px;
    padding-inline: 16px;
    margin-bottom: 6px;
    padding-bottom: 6px;
}

.OrderAttachments__AttachmentsTable__DataTable {
    .DataTable__Col--name {
        padding-left: 8px;
    }
}

.OrderAttachments__AttachmentsTable__NameCell {
    display: flex;
    align-items: center;
    gap: 4px;
}

.OrderAttachments__UploadButton {
    font-size: 14px;

    .DataTable + & {
        margin-top: 6px;
    }
}
