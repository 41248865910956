@import '../../../../variables.scss';

.DealsKanban__ColumnTitle {
    display: flex;
    align-items: center;
    gap: 6px;
}

.DealsKanban__Card {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.DealsKanban__Card__Title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.DealsKanban__Card__Tuid {
    font-size: 12px;
    color: $color-grey-base;
}

.DealsKanban__Card__Counts {
    color: $color-grey-base;
    display: flex;
    gap: 3px;
    align-items: center;
}

.DealsKanban__Card__Counts__Number {
    font-size: 12px;
    min-width: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-grey-dark;

    .empty {
        color: $color-grey-light;
    }
}

.DealsKanban__Card__Counts__Divider {
    font-size: 12px;
    color: $color-grey-light;
}

.DealsKanban__Card__Description {
    font-size: 12px;
    color: $color-grey-base;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.DealsKanban__Card__Flags {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    margin-block: 8px;
    flex-wrap: wrap;
}

.DealsKanban__Card__Flags__Tooltip {
    font-size: 12px;
}

// .DealsKanban__Card__SalesRep {
//     display: flex;
//     gap: 6px;
//     font-size: 12px;
//     align-items: center;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
