@import '../../variables.scss';

.Numpad__Container {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.Numpad__Display {
    font-size: 16px;
    font-weight: $font-weight-semi-bold;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: white;
    min-height: 52px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 16px;

    .placeholder {
        font-weight: $font-weight-normal;
        color: rgba(0, 0, 0, 0.5);
    }
}

.Numpad__Invalid {
    color: $color-danger-base;
}

.Numpad__Main {
    display: flex;
    gap: 6px;
}

.Numpad__Main__ButtonGrid {
    display: flex;
    gap: 6px;
    flex-direction: column;

    .MyButton {
        flex: 1;
    }
}

.Numpad__Main__ButtonGrid__Row {
    display: flex;
    gap: 6px;
}

.Numpad__FractionButton.MuiButton-root {
    background-color: $color-warning-base;
    min-width: 52px;
    padding: 0;

    &:hover {
        background-color: $color-warning-light;
    }
}

.Numpad__FractionLabel {
    vertical-align: middle;
    .numerator {
        font-size: 13px;
        font-weight: $font-weight-semi-bold;
        position: relative;
        top: -8px;
    }
    .divider {
        font-size: 24px;
        font-weight: $font-weight-light;
        display: inline-block;
        transform: rotateZ(20deg);
    }
    .denominator {
        font-size: 13px;
        font-weight: $font-weight-semi-bold;
        position: relative;
        top: 1px;
    }
}

.Numpad__Footer {
    display: flex;
    gap: 6px;
    // justify-content: stretch;

    .MyButton {
        flex: 1;
    }
}
