@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400;600;700;900&display=swap');
@import 'palette.module.scss';
$fontFamily: Inter, sans-serif;

$font-weight-thin: 100;
$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;

// breakpoints for responsive layouts
// each value is the minimum for the size
// small starts from 0px
$breakpoint-small: 448px; // small phones
$breakpoint-medium: 768px; // large phones / small tablet
$breakpoint-large: 1280px; // ipad -> small laptops

$screen: 'only screen';

$very-small-only: '#{$screen} and (max-width: #{$breakpoint-small - 1})';
$small-only: '#{$screen} and (min-width: #{$breakpoint-small}) and (max-width: #{$breakpoint-medium - 1})';
$medium-only: '#{$screen} and (min-width: #{$breakpoint-medium}) and (max-width: #{$breakpoint-large - 1})';
$large-only: '#{$screen} and (min-width: #{$breakpoint-large})';

$small-down: '#{$screen} and (max-width: #{$breakpoint-medium - 1})';
$small-up: '#{$screen} and (min-width: #{$breakpoint-small})';
$medium-down: '#{$screen} and (max-width: #{$breakpoint-large - 1})';
$medium-up: '#{$screen} and (min-width: #{$breakpoint-medium})';

// Site layout variables
$topBarHeight: 52px;
$mainNavWidth: 200px;
$sideBarWidthLarge: 272px;

$modalFramePaddingLarge: 32px;
$modalFramePaddingSmall: 12px;

$modal-full-height: calc(100vh - 48px);

@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}
