@import '../../variables.scss';

.MessagePanel {
    padding: 12px 16px;
    border-radius: 6px;

    p,
    ul {
        margin: 0;

        &:not(:first-child) {
            margin-top: 12px;
        }
    }

    &.MessagePanel--auto-margin {
        margin-top: -12px;
        margin-bottom: 24px;
    }
}

@keyframes shake-anim {
    0% {
        transform: translateX(0);
    }
    15% {
        transform: translateX(0);
    }
    33% {
        transform: translateX(-20px);
    }
    66% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0);
    }
}

.MessagePanel__Inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 8px 12px;

    .MessagePanel--shake & {
        animation: shake-anim 0.25s ease;
    }
}

.MessagePanel__Inner__Title {
    width: 100%;
    font-size: 16px;
    font-weight: $font-weight-bold;
    margin: 0;
}
.MessagePanel__Inner__Content {
    flex: 2;
    min-width: 160px;

    .MessagePanel--with-actions & {
        min-height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.MessagePanel__Inner__Actions {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .MyButton {
        padding: 0 16px;
        align-self: flex-end;
    }
}

.MessagePanel--info {
    background-color: $color-primary-lightest;
    border: 1px solid $color-primary-base;
    color: $color-primary-dark;
}

.MessagePanel--warning {
    background-color: $color-warning-lighter;
    border: 1px solid $color-warning-base;
    color: $color-warning-darker;
}

.MessagePanel--error {
    background-color: $color-danger-lighter;
    border: 1px solid $color-danger-base;
    color: $color-danger-darker;
}
