@import '../../../../variables.scss';

.CardWidget {
    background-color: white;
    padding: 18px 8px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    height: auto;
    border-radius: 4px;
}

.CardWidget__Title {
    display: flex;
    align-items: center;
}

.CardWidget__Count {
    font-size: 30px;
    font-weight: $font-weight-semi-bold;
}
