@import '../../../../variables.scss';

.BundleGroup {
    display: flex;
    flex-direction: column;
    background-color: rgba($color: $color-secondary-light, $alpha: 0.5);
    border-radius: 5px;
    margin-bottom: 24px;
}

.BundleGroup__Header {
    background-color: rgba($color: $color-secondary-dark, $alpha: 0.1);
    padding: 0px 24px;
    border-radius: 5px 5px 0 0;
    .disabled {
        color: $color-secondary-base;
    }
}

.BundleGroup__Header__Title {
    margin: 12px 0px;
    color: $color-secondary-base;
}

.BundleGroup__Bundle {
    padding: 12px 24px 24px;

    &.disabled {
        color: rgba(0, 0, 0, 0.4);
    }
    &:not(:last-child) {
        border-bottom: 2px dashed rgba($color: $color-secondary-base, $alpha: 0.25);
    }
}

.BundleGroup__Bundle__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BundleGroup__Bundle__Header__Name {
    font-weight: bold;
    font-size: 16px;
    flex: 1;
}

.BundleGroup__Bundle__Header__EditButton.MuiButton-root {
    padding: 4px;
    height: 24px;
}

.BundleGroup__Bundle__Fields {
    margin-top: 12px;
    display: table;
    font-size: 12px;
    border-left: 4px solid rgba($color: $color-secondary-base, $alpha: 0.25);
}

.BundleGroup__Bundle__Fields__Field {
    display: table-row;
}

.BundleGroup__Bundle__Fields__Field__Label {
    display: table-cell;
    padding-right: 24px;
    padding-left: 12px;
}

.BundleGroup__Bundle__Fields__Field__Value {
    display: table-cell;
}
