@import '../../../../variables.scss';

.OrderProductOptionsModal__Frame {
    width: 900px;
    display: flex;
    flex-direction: column;
}

.OrderProductOptionsModal__PageHeader {
    .PageHeader__TitleContainer__Title {
        line-height: 32px;
        font-size: 20px;
    }
}

.OrderProductOptionsModal__ProductMetrics {
    display: flex;
    flex-direction: row;
    margin: 6px 0;
    gap: 4px;
}

.OrderProductOptionsModal__QuantityBadge {
    font-size: 12px;
    padding: 0 12px;
    background-color: $color-secondary-light;
    color: $color-secondary-dark;
    border-radius: 24px;
}

.OrderProductOptionsModal__Body {
    display: flex;
    flex-direction: column;
    position: relative;

    @media #{$small-down} {
        margin: -12px -16px 0;
    }
    @media #{$medium-up} {
        margin: -24px -24px 0;
    }
}

.OrderProductOptionsModal__ContentFrame {
    width: 100%;
    border-color: white;
    border: 0;
    flex: 1;
}
.OrderProductOptionsModal__LoadingOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.OrderProductOptionsModal__ErrorDialog__Title {
    color: $color-danger-base;
    margin-bottom: 24px;
}
.OrderProductOptionsModal__ErrorDialog__Message {
    margin-bottom: 24px;
}
