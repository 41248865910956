@import '../../../../variables.scss';

.PurchaseOrderStatusBadge {
    &.SUBMITTED {
        background-color: white;
        color: $color-checked-green;
        border-color: $color-checked-green;
    }
    &.OPEN {
        background-color: $color-primary-base;
        border-color: $color-primary-base;
        color: white;
    }
}
