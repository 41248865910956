@import '../../../../variables.scss';

.PurchaseOrderReviewModal__Frame {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    transition: width 0.3s ease-in-out;
}

.PurchaseOrderReviewModal--form__Frame {
    width: 550px;
}

.PurchaseOrderReviewModal--review__Frame {
    width: 1200px;
}

.PurchaseOrderReviewModal__PageHeader {
    margin-bottom: 12px !important;
}

.PurchaseOrderReviewModal__Body {
    display: flex;
    flex-direction: column;

    @media #{$small-down} {
        margin: -12px -16px 0;
    }
    @media #{$medium-up} {
        margin: -24px -32px 0;
    }
}

.PurchaseOrderReviewModal__FormStep,
.PurchaseOrderReviewModal__ReviewStep {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.PurchaseOrderReviewModal__FormStep__Container {
    flex: 1;
    gap: 24px;

    @media #{$small-down} {
        padding: 12px 16px;
    }
    @media #{$medium-up} {
        padding: 24px 32px;
    }

    .PropertyEditDate__InputWrapper,
    .PropertyEditDate__Input {
        width: 100%;
    }

    .PropertyEdit__Label__Hint strong {
        font-weight: $font-weight-semi-bold;
    }
}

.PurchaseOrderReviewModal__StaticAddressDisplay {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: -14px;
    color: $color-secondary-base;
    .icon {
        color: $color-grey-light;
        font-size: 18px;
    }
}

.PurchaseOrderReviewModal__ReviewStep__Loading {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PurchaseOrderReviewModal__ReviewStep__ContentFrame {
    width: 100%;
    border-color: white;
    border: 0;
    flex: 1;
}

.PurchaseOrderReviewModal__FormStep__Footer,
.PurchaseOrderReviewModal__ReviewStep__Footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    background-color: white;
    position: sticky;
    border-radius: 0 0 12px 12px;
    gap: 6px;

    .MyButton--Primary,
    .MyButton--Hollow,
    .MyButton--Accent {
        min-width: 140px;
    }

    @media #{$small-down} {
        bottom: 0;
        padding: 16px;
    }
    @media #{$medium-up} {
        bottom: -40px;
        padding: 16px 32px 32px;
    }
}

.PurchaseOrderReviewModal__ReviewStep__Footer {
    justify-content: space-between;
}

.PurchaseOrderReviewModal__ReviewStep__Footer__Buttons {
    display: flex;
    gap: 6px;
}
