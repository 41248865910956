@import '../../variables.scss';

.MyMultiEmailInput {
    .MuiInputBase-root {
        height: auto;
        min-height: 40px;
        display: flex;
        gap: 4px;
    }
}

.MyMultiEmailInput__EmailChip {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: $color-secondary-base;
    color: white;
    border-radius: 24px;
    padding: 1px 12px;
    font-size: 12px;
}

.MyMultiEmailInput__EmailChip--invalid {
    background-color: $color-danger-base;
}

.MuiButtonBase-root.MyMultiEmailInput__EmailChip__Button {
    min-width: 0;
    padding: 2px;

    .MyButton__Inner__IconLeft {
        color: white;
        font-size: 14px;
    }

    &:hover {
        background-color: transparent;

        .MyButton__Inner__IconLeft {
            color: rgba(0, 0, 0, 0.4);
        }
    }
}
