@import '../../variables.scss';
.BackendFilterStrip {
    display: flex;
    flex-direction: row;
    gap: 8px 6px;
    flex-wrap: wrap;
    align-items: center;
}
.BackendFilterStrip .FilterStrip__Filters {
    flex: 0 1 auto;
}

@keyframes criteria-refresh-icon-spin {
    from {
        transform: rotateZ(0);
    }
    to {
        transform: rotateZ(-359deg);
    }
}

.BackendFilterStrip__FilteredResetPanel {
    flex: 0 1;
    display: flex;
    align-self: stretch;
}
.MyButton.BackendFilterStrip__ResetButton,
.MyButton.BackendFilterStrip__RefreshButton {
    padding: 0 8px;
    min-width: 0;

    &.refreshing {
        .MyButton__Inner__IconRight {
            animation: criteria-refresh-icon-spin 1s infinite;
            color: $color-grey-light;
        }
    }
}
