@import '../../../../variables.scss';

.DealsTable {
    .DataTable__DataCell--quoteCount,
    .DataTable__DataCell--orderCount {
        padding-block: 0px;
    }
}

.DealsTable__StatusCell {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: space-between;

    .WorkflowStatusBadge {
        font-size: 13px;
    }
    .WorkflowStatusSelect.MuiInputBase-root {
        margin-top: -5px;
    }
}

.DealsTable__DescriptionCell {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.DealsTable__CountCell {
    &.empty {
        color: $color-grey-light;
    }
}
